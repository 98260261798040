.ui.toggle.checkbox.togglePricing .box:before, .ui.toggle.checkbox label:before {
    width: 18.5rem;
    height: 3.5rem;
    background-color: #D1D4DC !important;
}

.ui.toggle.checkbox.togglePricing input:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before {
    background-color: #D1D4DC !important;
}

.ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before {
    background-color: #D1D4DC !important;
    content: 'Monthly' !important;
    padding-left: 120px;
    padding-top: 14px;
    font-weight: 300;
    color: rgba(0,0,0,.2) !important;
    font-size: 17px;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-style: normal;
}

.ui.toggle.checkbox .box:after, .ui.toggle.checkbox input:checked~label:before {
    background-color: #D1D4DC !important;
    content: 'One-time' !important;
    padding-top: 14px;
    font-weight: 300;
    text-align: left;
    color: rgba(0,0,0,.2) !important;
    font-size: 17px;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-style: normal;
}

.ui.toggle.checkbox .box:after, .ui.toggle.checkbox label:after {
    width: 9.5rem;
    height: 3.5rem;
    content: 'One-time' !important;
    color: rgba(0,0,0,.6) !important;
    font-weight: 700;
    font-size: 17px;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-style: normal;
    padding: 14px;
}

.ui.toggle.checkbox input:checked~.box:after, .ui.toggle.checkbox input:checked~label:after {
    left: 9.25rem;
    content: 'Monthly' !important;
    color: #57FE76 !important;
    font-weight: 700;
    font-size: 17px;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-style: normal;
    padding: 14px;
}

.togglePricing {
    margin: 20px;
    right: 100px;
}