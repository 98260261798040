@tailwind base;
@tailwind components;
@tailwind utilities;

.theme-dark {
    --color-primary-btn: linear-gradient(#57FE76, #1EBAC4);
    --color-primary-bg: #13131C;
    --color-on-primary-bg: #161620;
    --color-on-primary-bg-soft: #1E1E29;
    --color-primary-text: #DEDFE9;
    --color-on-hover-primary-text: #6E6E89;

    --color-secondary: #FFFF; 
    --color-secondary-bg: #132125;
    --color-on-secondary: #FFFF;

    --color-accent: #24383E;
}

.theme-light {
    --color-primary-text: var(--color-primary-100);
    --color-secondary-text: var(--color-primary-100);
    --color-primary-background: var(--color-primary-100);
    --color-secondary-background: var(--color-primary-100);
}

.login-button {
    margin-left: 50px !important;
    width: 120px !important;
}

.ui.loader.active {
    position: relative !important;
}